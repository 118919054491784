import React from 'react'
import { useDispatch } from 'react-redux';
import { activarCurso, actualizarCurso } from '../../../action/curso';
import { libroDigitalFormCursoActualizar, libroDigitalTablaEstudiantes, libroDigitalTablaPeriodosCurso } from '../../../action/router';
import { formatFecha } from '../../../helpers/formatData';

export const RowCurso = ({curso, aniosEscolares, docentes, index}) => {
  const dispatch=useDispatch();
  const { nombre, anioEscolar, docente, estado } = curso;
  const anioEscolarPrincipal = aniosEscolares.find(e=> e._id=== anioEscolar);
  const docenteActual = docentes.find(e=>e._id===docente);

  const handleActivar = () => {
    curso.estado=true;
    dispatch(actualizarCurso(curso))
  }

  const handleDesactivar = () => {
    curso.estado=false;
    dispatch(actualizarCurso(curso))
  }

  const handleMatricula = () => {
    dispatch(activarCurso(curso._id))
    dispatch(libroDigitalTablaEstudiantes())
  }
  const handlePeriodos = () => {
    dispatch(activarCurso(curso._id))
    dispatch(libroDigitalTablaPeriodosCurso())
  }

  const handleActualizar = () => {
    dispatch(activarCurso(curso._id))
    dispatch(libroDigitalFormCursoActualizar())
  }

  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{nombre}</td>
      <td>{formatFecha(anioEscolarPrincipal.anioEscolar)}</td>
      <td>{docenteActual.nombres} {docenteActual.apellidoPaterno} {docenteActual.apellidoMaterno}</td>
      <td><div className='d-grid gap-2'><button className='btn btn-primary btn-sm' onClick={handleMatricula} >Matricula</button></div></td>
      <td>
        {estado 
          ? <div className='d-grid gap-2'><button className='btn btn-success btn-sm' onClick={handleDesactivar} >Activo</button></div>
          : <div className='d-grid gap-2'><button className='btn btn-danger btn-sm' onClick={handleActivar} >Inactivo</button></div>
        }
      </td>
      <td><div className='d-grid gap-2'><button className='btn btn-primary btn-sm' onClick={handlePeriodos} >Periodos</button></div></td>
      <td><div className='d-grid gap-2'><button className='btn btn-warning btn-sm' onClick={handleActualizar} >Modificar</button></div></td>
    </tr>
  )
}
