import React from 'react'
import { useDispatch } from 'react-redux';
import { activarEstudiante } from '../../../action/estudiante';
import { libroDigitalFormMatriculaNueva } from '../../../action/router';
import { formatFecha } from '../../../helpers/formatData';

export const RowMatriculasEstudiante = ({estudiante, matriculas, index}) => {
  const dispatch = useDispatch();
  const matriculasEstudiante= matriculas.find(e=> e.estudiante === estudiante._id)
  console.log(matriculasEstudiante)
  const { nombres, apellidoPaterno, apellidoMaterno, fechaNacimiento, estado } = estudiante;

  const handleMatricular = () => {
    dispatch(activarEstudiante(estudiante._id));
    dispatch(libroDigitalFormMatriculaNueva());
  }

  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{`${nombres} ${apellidoPaterno} ${apellidoMaterno}`}</td>
      <td>{formatFecha(fechaNacimiento)}</td>
      <td>
        {estado 
          ? <div className='d-grid gap-2'><button className='btn btn-success btn-sm' disabled>activo</button></div>
          : <div className='d-grid gap-2'><button className='btn btn-danger btn-sm' disabled>inactivo</button></div>
        }
      </td>
      <td>
        {!matriculasEstudiante 
          ?
            <div className='d-grid gap-2'>
              <button className='btn btn-primary' onClick={handleMatricular}>Matricular</button>
            </div>
          : 
            <div className='d-grid gap-2'>
              <button className='btn btn-danger' disabled>Matriculado</button>
            </div>
        }
      </td>
    </tr>
  )
}
