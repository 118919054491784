import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { activarPeriodoEscolar, actualizarPeriodoEscolar } from '../../../action/periodoEscolar';
import { libroDigitalFormPeriodoEscolarActualizar } from '../../../action/router';
import { getAnio } from '../../../helpers/formatData';

export const RowPeriodoEscolar = ({periodoEscolar, index}) => {
  const dispatch = useDispatch()
  const { periodo, anioEscolar, estado, establecimiento } = periodoEscolar;
  const {establecimientos} = useSelector(state=> state.establecimiento);
  const {aniosEscolares} = useSelector(state=> state.libroDigital);
  const establecimientoPrincipal = establecimientos.find(e=> e._id=== establecimiento);
  const anioEscolarPrincipal = aniosEscolares.find(e=> e._id=== anioEscolar);

  const handleActivar = () => {
    periodoEscolar.estado='true'
    dispatch(actualizarPeriodoEscolar(periodoEscolar))
  }

  const handleDesactivar = () => {
    periodoEscolar.estado='false'
    dispatch(actualizarPeriodoEscolar(periodoEscolar))
  }

  const handleActualizar = () => {
    dispatch(activarPeriodoEscolar(periodoEscolar._id))
    dispatch(libroDigitalFormPeriodoEscolarActualizar())
  }


  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{periodo}</td>
      <td>{getAnio(anioEscolarPrincipal.anioEscolar)}</td>
      <td>{establecimientoPrincipal.nombre}</td>
      <td>
        {estado 
          ? <div className='d-grid gap-2'><button className='btn btn-success btn-sm' onClick={handleDesactivar} >Activo</button></div>
          : <div className='d-grid gap-2'><button className='btn btn-danger btn-sm' onClick={handleActivar} >Inactivo</button></div>
        }
      </td>
      <td><div className='d-grid gap-2'><button className='btn btn-warning btn-sm' onClick={handleActualizar}>Modificar</button></div></td>
    </tr>
  )
}
