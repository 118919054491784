import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { activarPeriodoEscolar } from '../../../action/periodoEscolar';
import { libroDigitalFormPeriodoEscolarActualizar, libroDigitalTablaAsignaturas, libroDigitalTablaTalleres } from '../../../action/router';
import { getAnio } from '../../../helpers/formatData';

export const RowPeriodoCurso = ({periodoEscolar, index}) => {
  const dispatch = useDispatch()
  const { periodo, anioEscolar, establecimiento } = periodoEscolar;
  const {establecimientos} = useSelector(state=> state.establecimiento);
  const {aniosEscolares} = useSelector(state=> state.libroDigital);
  const establecimientoPrincipal = establecimientos.find(e=> e._id=== establecimiento);
  const anioEscolarPrincipal = aniosEscolares.find(e=> e._id=== anioEscolar);


  const handleAsignaturas = () => {
    dispatch(activarPeriodoEscolar(periodoEscolar._id))
    dispatch(libroDigitalTablaAsignaturas())
  }

  const handleTalleres = () => {
    dispatch(activarPeriodoEscolar(periodoEscolar._id))
    dispatch(libroDigitalTablaTalleres())
  }


  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{periodo}</td>
      <td>{getAnio(anioEscolarPrincipal.anioEscolar)}</td>
      <td>{establecimientoPrincipal.nombre}</td>
      <td><div className='d-grid gap-2'><button className='btn btn-primary btn-sm' onClick={handleAsignaturas}>Asignaturas</button></div></td>
      <td><div className='d-grid gap-2'><button className='btn btn-primary btn-sm' onClick={handleTalleres}>Talleres</button></div></td>
      
      
    </tr>
  )
}
